<template>
  <ion-page>
    <LoggedInNav
      :title="'Eliminar cuenta'"
      :back="true"
      :backUrl="'/configuracion/datosPersonales'"
    />

    <ion-content>
      <div class="centerCenter">
        <div class="contenedor">
        <div class="centerCenter" style="margin-top: 100px">
          <img src="../../../../../public/assets/img/sad-goodbye.svg" />
        </div>

        <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 20px 0 2.5px 0;
          "
        >
          <span class="parrafo-small"> Cuenta eliminada. </span>
        </div>

        <div
          style="display: flex; align-items: center; justify-content: center"
        >
          <span class="parrafo-small"> Sentimos mucho verte partir! </span>
        </div>

        <div style="margin-top: 40px">
          <ion-button
            class="btn buttonIniciar"
            expand="block"
            fill="solid"
            v-on:click="cerrar"
            >Cerrar</ion-button
          >
        </div>
      </div>
      </div>
      
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonButton } from "@ionic/vue";
import { defineComponent } from "vue";
import router from "../../../../router/index";
import LoggedInNav from "../../../../components/LoggedInNav.vue";

export default defineComponent({
  name: "EliminarCuentabye",
  components: {
    IonPage,
    IonButton,
    LoggedInNav,
  },
  data() {
    return {};
  },
  methods: {
    cerrar() {
      router.push("/");
    },
  },
});
</script>



